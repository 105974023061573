import request from '../request'

//外出申请列表
export const go_out_index_request = p => {
    return request({
        method:'GET',
        url:'go_out/index',
        params: p
    })
}

//发起外出申请
export const go_out_add_request = d => {
    return request({
        method:'POST',
        url:'go_out/add',
        data: d
    })
}

//删除外出申请
export const go_out_del_request = id => {
    return request({
        method:'DELETE',
        url:'go_out/del',
        data: {
            id: id
        }
    })
}

//修改外出申请
export const go_out_edit_request = data => {
    return request({
        method:'PUT',
        url:'go_out/edit',
        data
    })
}

//获取外出申请详情
export const go_out_details_request = id => {
    return request({
        method:'GET',
        url:'go_out/details',
        params: {
            id
        }
    })
}

// 提交外出申请
export const go_out_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'go_out/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看外出申请审批记录
export const go_out_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'go_out/sel_approval',
        params: {
            id
        }
    })
}

// 审批外出申请
export const go_out_approval_request = d => {
    return request({
        method:'PUT',
        url:'go_out/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other,
            go_staff_id: d.go_staff_id
        }
    })
}

// 撤回外出申请
export const go_out_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'go_out/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印外出申请
export const go_out_print_request = id => {
    return request({
        method:'PUT',
        url:'go_out/print',
        data: {
            id
        }
    })
}

// 补充外出申请附件
export const go_out_add_file_request = data => {
    return request({
        method:'PUT',
        url:'go_out/add_file',
        data
    })
}